import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import LegalDoc from '../../modules/legal/components/legal-doc';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = function MerchantTerms({
  children
}) {
  return <LegalDoc title="MERCHANT OFFERS PLATFORM – TERMS OF SERVICE">
      {children}
    </LegalDoc>;
};

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`The below terms (“Terms of Service”) set out the rights and obligations of Fidel and Publisher in relation to the Merchant Offers Platform and use of any Merchant Content.`}</strong></p>
    <p><strong parentName="p">{`By clicking on the “Accept” button, the Publisher confirms that it accepts these Terms of Service.`}</strong></p>
    <p><strong parentName="p">{`IF YOU DO NOT ACCEPT THESE TERMS OF SERVICE, PLEASE DO NOT USE THE MERCHANT OFFERS PLATFORM.`}</strong></p>
    <ol>
      <li parentName="ol">{`Interpretation`}
        <ol parentName="li">
          <li parentName="ol">{`In these Terms of Service, save where the context requires otherwise, the following words and expressions have the following meanings:`}
            <ol parentName="li">
              <li parentName="ol"><strong parentName="li">{`"Account"`}</strong>{` means the Publisher’s account on the Merchant Offers Platform;`}</li>
              <li parentName="ol"><strong parentName="li">{`"American Express"`}</strong>{` means American Express Payment Services Limited and its affiliated companies;`}</li>
              <li parentName="ol"><strong parentName="li">{`"Commission"`}</strong>{` has the meaning set out in clause 8.1;`}</li>
              <li parentName="ol"><strong parentName="li">{`"Confidential Information"`}</strong>{` means information which is identified as confidential or proprietary by either party or by the nature of which is clearly confidential or proprietary;`}</li>
              <li parentName="ol"><strong parentName="li">{`"Fidel"`}</strong>{` means Enigmatic Smile (08869163) of the Swift House Ground Floor, 18 Hoffmanns Way, Chelmsford, Essex, England, CM1 1GU;`}</li>
              <li parentName="ol"><strong parentName="li">{`"Loyalty Programme"`}</strong>{` means a rewards service or a card-linked offers programme;`}</li>
              <li parentName="ol"><strong parentName="li">{`"MasterCard"`}</strong>{` means MasterCard Europe SA and its affiliated companies;`}</li>
              <li parentName="ol"><strong parentName="li">{`"Merchant"`}</strong>{` means each member of a Partner’s merchant network, which operates the point of sale equipment relating to a Transaction;`}</li>
              <li parentName="ol"><strong parentName="li">{`"Merchant Content"`}</strong>{` means any content provided or produced by a Partner on behalf of a Merchant relating to a card-linked offer made available by a Merchant and uploaded to the Merchant Offers Platform by Fidel, including the terms and conditions applicable to the offer;`}</li>
              <li parentName="ol"><strong parentName="li">{`"Merchant Offers Platform"`}</strong>{` means the online platform operated by Fidel in respect of which it may upload Merchant Content and which the Publisher can use to select the Merchant Content it wishes to make available or advertise in connection with its Loyalty Programme;`}</li>
              <li parentName="ol"><strong parentName="li">{`"Partner"`}</strong>{` means a party which is authorised to distribute Merchant Content on behalf of Merchants and provides Merchant Content to Fidel to be uploaded to the Merchant Offers Platform pursuant to a partnership agreement with Fidel;`}</li>
              <li parentName="ol"><strong parentName="li">{`"Payment Card"`}</strong>{` means a credit or debit card issued by a Payment Provider;`}</li>
              <li parentName="ol"><strong parentName="li">{`"Payment Provider"`}</strong>{` means a payment provider of Merchant authorised to provide Transaction Data to Fidel (for example Visa, MasterCard, or American Express);`}</li>
              <li parentName="ol"><strong parentName="li">{`"Publisher"`}</strong>{` means a customer of Fidel who has been granted access to the Merchant Offers Platform;`}</li>
              <li parentName="ol"><strong parentName="li">{`"Tier 1 Support"`}</strong>{` means first line user support on non-technical issues;`}</li>
              <li parentName="ol"><strong parentName="li">{`"Tier 2 Support"`}</strong>{` means second line support on technical and urgent issues;`}</li>
              <li parentName="ol"><strong parentName="li">{`"Transaction"`}</strong>{` means a point of sale purchase with a Merchant made by a User which results in the User redeeming Merchant Content made available by the Publisher;`}</li>
              <li parentName="ol"><strong parentName="li">{`"Transaction Data"`}</strong>{` means the following data set supplied via a Payment Provider relating to a Transaction: auth code, amount paid, date and time of the Transaction, Merchant ID and Fidel-generated transaction ID;`}</li>
              <li parentName="ol"><strong parentName="li">{`"User"`}</strong>{` means an individual who has enrolled their Payment Card(s) with the Publisher in order to receive rewards in connection with the Publisher’s Loyalty Programme;;`}</li>
              <li parentName="ol"><strong parentName="li">{`"Virus"`}</strong>{` mean any thing or device (including any software, code, file or programme) which may: prevent, impair or otherwise adversely affect the operation of any computer software, hardware or network, any telecommunications service, equipment or network or any other service or device; prevent, impair or otherwise adversely affect access to or the operation of any programme or data, including the reliability of any programme or data (whether by re-arranging, altering or erasing the programme or data in whole or part or otherwise); or adversely affect the user experience, including worms, Trojan horses, viruses and other similar things or devices; and`}</li>
              <li parentName="ol"><strong parentName="li">{`"Visa"`}</strong>{` means Visa Europe Services Inc. and its affiliated companies..`}</li>
            </ol>
          </li>
        </ol>
      </li>
      <li parentName="ol">{`Access to Merchant Offers Platform`}
        <ol parentName="li">
          <li parentName="ol">{`Fidel grants to the Publisher a limited, non-exclusive, non-transferable, non-sublicensable, personal right to access and use the Merchant Offers Platform only for the purposes of reviewing and selecting Merchant Content to make available and advertise to Users as part of Publisher’s Loyalty Programme, as permitted by the functionality of the Merchant Offers Platform and in strict accordance with these Terms of Service.`}</li>
          <li parentName="ol">{`To access the Merchant Offers Platform, the Publisher will first need to set up an Account.`}</li>
          <li parentName="ol">{`The individual creating the Account and accepting these Terms of Service on the Publisher’s behalf must have the necessary authority, power and right to fully bind the Publisher.`}</li>
          <li parentName="ol">{`Fidel encourages the Publisher to use "strong" passwords (passwords that use a combination of upper and lower case letters, numbers, and symbols) with its Account.`}</li>
          <li parentName="ol">{`The Publisher is responsible for maintaining the confidentiality of its login details and any activities that occur under its Account.`}</li>
          <li parentName="ol">{`The Publisher is also responsible for ensuring that all persons who it authorises to access the Merchant Offers Platform are aware of these Terms of Service and that they comply with them, and the Publisher shall be liable for the acts and omissions of such persons as if they are the acts and omissions of the Publisher.`}</li>
          <li parentName="ol">{`The Publisher must use all reasonable endeavours to prevent any unauthorised access to, or use of, the Merchant Offers Platform, and in the event of any such unauthorised access or use, as soon as reasonably practicable notify Fidel at `}<a parentName="li" {...{
              "href": "mailto:cs@fidelapi.com"
            }}>{`cs@fidelapi.com`}</a>{`.`}</li>
          <li parentName="ol">{`If the Publisher has any concerns about the login details for its Account or thinks that such details have been misused, the Publisher should promptly notify Fidel at `}<a parentName="li" {...{
              "href": "mailto:cs@fidelapi.com"
            }}>{`cs@fidelapi.com`}</a>{` and take such other steps as may reasonably be required by Fidel.`}</li>
          <li parentName="ol">{`The Publisher warrants that it shall not solicit or entice away (or attempt to solicit or entice away) from a Partner any business or custom of any of its Merchants`}</li>
          <li parentName="ol">{`Fidel may disable or suspend any Account at any time and at Fidel's sole discretion, if the Publisher fails, in any material respect, to comply with any of the provisions of these Terms of Service.`}</li>
          <li parentName="ol">{`The Publisher agrees that the Merchant Offers Platform may change from time to time, and no warranty, representation, or other commitment is given in relation to the continuity of any functionality of the Merchant Offers Platform.`}</li>
          <li parentName="ol">{`Fidel may monitor the Publisher’s use of the Merchant Offers Platform to ensure quality, improve the Merchant Offers Platform, and to verify the Publisher’s compliance with these Terms of Service.`}</li>
        </ol>
      </li>
      <li parentName="ol">{`Publisher’s obligations`}
        <ol parentName="li">
          <li parentName="ol">{`The Publisher:`}
            <ol parentName="li">
              <li parentName="ol">{`must comply with all applicable laws and regulations with respect to its use of the Merchant Offers Platform and its activities under these Terms of Service;`}</li>
              <li parentName="ol">{`must use the Merchant Offers Platform in accordance with the terms of these Terms of Service;`}</li>
              <li parentName="ol">{`only advertise or make available the Merchant Content to its Users in accordance with:`}
                <ol type="a">
  <li>the terms and conditions relating to such Merchant Content;</li>
  <li>
    applicable advertising regulations, standards, industry codes or
    regulatory guidelines; and
  </li>
  <li>any other applicable laws;</li>
                </ol>
              </li>
              <li parentName="ol">{`must ensure that its network and systems, including its internet browser used, complies with the relevant specifications provided by Fidel in writing (including email) from time to time;`}</li>
              <li parentName="ol">{`is solely responsible for procuring and maintaining its network connections and telecommunications links from its systems in order to access and use the Merchant Offers Platform; and`}</li>
              <li parentName="ol">{`shall not: (a) access, store, distribute or transmit any Virus through the Merchant Offers Platform (b) use the Merchant Offers Platform to access, store, distribute or transmit any material that is unlawful, inaccurate, harmful, threatening, defamatory, obscene, infringing, harassing or racially or ethnically offensive; (c) use the Merchant Offers Platform in a manner that is illegal or causes damage or injury to any person or property; (d) use any automated system, including without limitation "robots," "spiders," "offline readers," to access the Merchant Offers Platform in a manner that sends more request messages to the Merchant Offers Platform than a human can reasonably produce in the same period of time by using a conventional online web browser, or conduct any denial of service attack on the Merchant Offers Platform; (e) attempt to interfere with or compromise the integrity or security of the Merchant Offers Platform. Fidel reserves the right, without liability or prejudice to its other rights under these Terms of Service, to disable the Publisher’s Account for material or persistent breaches of the provisions of this clause 3.1.6.`}</li>
            </ol>
          </li>
          <li parentName="ol">{`The Publisher agrees that failure to comply with this clause constitutes a material breach of these Terms of Service, and may result in Fidel taking all or any of the following actions:`}
            <ol parentName="li">
              <li parentName="ol">{`immediate, temporary or permanent withdrawal of any rights to use the Merchant Offers Platform or any Merchant Content; or`}</li>
              <li parentName="ol">{`any other action Fidel in its sole discretion considers to be necessary.`}</li>
            </ol>
          </li>
          <li parentName="ol">{`The Publisher shall indemnify and defend Fidel and each of its officers, directors, employees, agents and contractors from and against any and all losses, damages, claims, liabilities or expenses (including reasonable lawyers' fees) arising out of any claim brought by a third party relating to the Publisher’s use of the Merchant Offers Platform or Merchant Content (except to the extent caused by Fidel's negligence).`}</li>
        </ol>
      </li>
      <li parentName="ol">{`Merchant Content`}
        <ol parentName="li">
          <li parentName="ol">{`If the Publisher selects Merchant Content from the Merchant Offers Platform which requires approval from either the Merchant or the Partner (as set out in the Merchant Content), Fidel will notify the relevant Merchant or Partner (as applicable) and seek approval for the Publisher to advertise such Merchant Content. In such circumstances, the Publisher will not be able to advertise the Merchant Content until such time as the relevant Merchant’s or Partner’s approval has been obtained.`}</li>
          <li parentName="ol">{`Fidel may require the Publisher to update any Merchant Content the Publisher has selected to make available or advertise as part of its Loyalty Programme within 24 hours after Fidel provides the Publisher written notice in the event any changes are required:`}
            <ol parentName="li">
              <li parentName="ol">{`to comply with:`}
                <ol type="a">
  <li>the terms and conditions relating to such Merchant Content;</li>
  <li>
    applicable advertising regulations, standards, industry codes or
    regulatory guidelines; or
  </li>
  <li>any other applicable laws; or</li>
                </ol>
              </li>
              <li parentName="ol">{`for any other reason Fidel, acting reasonably, considers to be necessary.`}</li>
            </ol>
          </li>
          <li parentName="ol">{`The Publisher acknowledges and agrees that Fidel may at its sole discretion require the Publisher to immediately cease making available or advertising any Merchant Content as part of the Publisher’s Loyalty Programme.`}</li>
        </ol>
      </li>
      <li parentName="ol">{`Fidel as agent`}
        <ol parentName="li">
          <li parentName="ol">{`Partners are responsible for providing the Merchant Content uploaded to the Merchant Offers Platform by Fidel on the Partners’ behalf. The Publisher acknowledges that Fidel acts as an agent on behalf of the Partners in making available the Merchant Content on the Merchant Offers Platform.`}</li>
          <li parentName="ol">{`In the event the Publisher elects to make available or advertise any Merchant Content to its Users as part of its Loyalty Programme, the Publisher acknowledges and agrees that it is contracting with the relevant Partner as a principal on the terms set out in the Merchant Content for its use of the Merchant Content, and the Partner shall be solely responsible for paying any Commission due to the Publisher in accordance with clause 8 (Payment of Commission) and any applicable Partner terms`}</li>
          <li parentName="ol">{`Publisher acknowledges and agrees that Fidel shall have no liability whatsoever under any agreement relating to the Merchant Content, including under any agreement between the Publisher and Partner.. The Publisher shall indemnify and defend Fidel, its agents and contractors, from and against any and all losses, damages, claims, liabilities, and expenses (including reasonable lawyers' fees) arising out of any claim by any Partner arising from the Publisher’s breach of such contract, use of the Merchant Content, or these Terms of Service.`}</li>
        </ol>
      </li>
      <li parentName="ol">{`Intellectual property rights`}
        <ol parentName="li">
          <li parentName="ol">{`Fidel is the owner of or the licensee of all intellectual property rights in the Merchant Offers Platform. These works are protected by copyright and other laws and treaties around the world. All such rights are reserved.`}</li>
          <li parentName="ol">{`The Publisher will not, when using the Merchant Offers Platform:`}
            <ol parentName="li">
              <li parentName="ol">{`attempt to copy, modify, duplicate, create derivative works from, frame, mirror, republish, download, display, transmit, or distribute all or any portion of the Merchant Offers Platform in any form or media or by any means; or`}</li>
              <li parentName="ol">{`attempt to reverse compile, disassemble, reverse engineer or otherwise reduce to human-perceivable form all or any part of the Merchant Offers Platform; or`}</li>
              <li parentName="ol">{`access all or any part of the Merchant Offers Platform in order to build a product or service which competes with the Merchant Offers Platform or use or attempt to use the Merchant Offers Platform to directly compete with Fidel.`}</li>
            </ol>
          </li>
          <li parentName="ol">{`The Publisher shall only use the Merchant Content as part of its Loyalty Programme in accordance with the restrictions and any terms made available as part of such Merchant Content. The Publisher acknowledges and agrees that, in relation to Merchant Content selected by a Publisher that is subsequently provided by a Partner to the Publisher ("`}<strong parentName="li">{`Active Merchant Content`}</strong>{`"), a Partner may, in relation to such Active Merchant Content only, refer to the Publisher as a partner while Active Merchant Content is in effect and show prospective customers, partners, and sponsors related materials to support Partner’s efforts to secure Merchant Offers and/or other business.`}</li>
        </ol>
      </li>
      <li parentName="ol">{`Confidential Information`}
        <ol parentName="li">
          <li parentName="ol">{`Each party may be given access to Confidential Information from the other party in order to perform its obligations under these Terms of Service. A party's Confidential Information shall not be deemed to include information that:`}
            <ol parentName="li">
              <li parentName="ol">{`is or becomes publicly known other than through any act or omission of the receiving party;`}</li>
              <li parentName="ol">{`was in the other party's lawful possession before the disclosure;`}</li>
              <li parentName="ol">{`is lawfully disclosed to the receiving party by a third party without restriction on disclosure;`}</li>
              <li parentName="ol">{`is independently developed by the receiving party, which independent development can be shown by written evidence; or`}</li>
              <li parentName="ol">{`is required to be disclosed by law, by any court of competent jurisdiction or by any regulatory or administrative body.`}</li>
            </ol>
          </li>
          <li parentName="ol">{`Each party shall hold the other's Confidential Information in confidence and, unless required by law, not make the other's Confidential Information available for use for any purpose other than as needed to perform its obligations under these Terms of Service during the Term and for so long as the Confidential Information remains confidential.`}</li>
          <li parentName="ol">{`Each party shall take all reasonable steps to ensure that the other's Confidential Information to which it has access is not disclosed or distributed by its employees or agents in violation of the terms of these Terms of Service.`}</li>
          <li parentName="ol">{`Each party shall use the same degree of care to protect the Confidential Information as it uses to protect its own most highly confidential information, but in no circumstances less than reasonable care.`}</li>
          <li parentName="ol">{`At the time of expiration or termination of these Terms of Service, as applicable, or upon the other party’s request, each party agrees to promptly return or destroy any Confidential Information provided by the other party in connection with these Terms of Service.`}</li>
          <li parentName="ol">{`Each party acknowledges and agrees that, as between the parties, Confidential Information disclosed by the disclosing party to the receiving party will at all times remain the property of the disclosing party. No licence under any trade secrets, copyrights, or other rights is granted by any disclosure of Confidential Information under these Terms of Service.`}</li>
        </ol>
      </li>
      <li parentName="ol">{`Payment of Commission`}
        <ol parentName="li">
          <li parentName="ol">{`The Partner providing the relevant Merchant Content shall be responsible for ascertaining the commission payable by the Merchant to the Publisher in respect of a Transaction ("`}<strong parentName="li">{`Commission`}</strong>{`").`}</li>
          <li parentName="ol">{`The Commission shall be payable by the Partner to the Publisher in accordance with the payment terms specified as part of the Merchant Content. Such payment terms shall govern any dispute between the Publisher and the Partner regarding the amount of any payment due to the Publisher.`}</li>
          <li parentName="ol">{`The Publisher acknowledges that the Partner is not obliged to pay any Commission to Publisher until the date falling 30 days after Partner receives the full sums relating to the Commission from the Merchant and that the Partner will have no liability to Publisher in relation to the Commission for any failure of any Merchant to comply with the terms of their agreements with Partner or Publisher, including making any payments to Partner or Publisher. For the avoidance of doubt, the Publisher acknowledges and agrees that Fidel shall have no liability to the Publisher whatsoever in connection with any Partner’s failure to pay the Publisher the Commission due to it in accordance with this clause 8.`}</li>
        </ol>
      </li>
      <li parentName="ol">{`Availability and support`}
        <ol parentName="li">
          <li parentName="ol">{`Fidel will use commercially reasonable endeavours to make the Merchant Offers Platform available with an uptime rate of 99% except for:`}
            <ol parentName="li">
              <li parentName="ol">{`planned maintenance for which 24 hours’ notice will be given; or`}</li>
              <li parentName="ol">{`unscheduled maintenance during normal business hours (UK time) or otherwise, for which Fidel will use reasonable endeavours to give the Publisher advance notice.`}</li>
            </ol>
          </li>
          <li parentName="ol">{`Fidel will provide the Publisher with Tier 1 Support and Tier 2 Support during normal business hours of Fidel. The Publisher may raise Tier 1 or Tier 2 Support tickets with Fidel via email. Fidel shall respond to email support queries no later than 24 hours after receipt.`}</li>
          <li parentName="ol">{`The Publisher acknowledges that the provision of the Merchant Offers Platform is dependent on access to various third-party data sources. The Publisher agrees that Fidel is not responsible for the non-availability or interruption to the Merchant Offers Platform caused by any such non-availability of any such third-party data source unless such non-availability or interruption is caused by Fidel acting negligently.`}</li>
        </ol>
      </li>
      <li parentName="ol">{`Suspension and termination`}
        <ol parentName="li">
          <li parentName="ol">{`These Terms of Service will automatically end on Publisher ceasing to be a customer of Fidel.`}</li>
          <li parentName="ol">{`Either party may terminate these Terms of Service on 30 days’ notice in writing.`}</li>
          <li parentName="ol">{`Either party may terminate these Terms of Service at any time on written notice to the other if the other:`}
            <ol parentName="li">
              <li parentName="ol">{`is in material or persistent breach of these Terms of Service and either that breach is incapable of remedy, or the other party fails to remedy that breach within 30 days after receiving written notice requiring it to remedy that breach;`}</li>
              <li parentName="ol">{`is unable to pay its debts (within the meaning of section 123 of the Insolvency Act 1986), or becomes insolvent, or is subject to an order or a resolution for its liquidation, administration, winding-up or dissolution (otherwise than for the purposes of a solvent amalgamation or reconstruction), or has an administrative or other receiver, manager, trustee, liquidator, administrator or similar officer appointed over all or any substantial part of its assets, or enters into or proposes any composition or arrangement with its creditors generally, or is subject to any analogous event or proceeding in any applicable jurisdiction; or`}</li>
              <li parentName="ol">{`is in breach of applicable law.`}</li>
            </ol>
          </li>
          <li parentName="ol">{`Upon termination of these Terms of Service:`}
            <ol parentName="li">
              <li parentName="ol">{`all licences granted under these Terms of Service shall immediately terminate and the Publisher's right to access and use the Merchant Offers Platform will end; and`}</li>
              <li parentName="ol">{`the Publisher shall immediately cease to make available or advertise any Merchant Content unless otherwise permitted.`}</li>
            </ol>
          </li>
          <li parentName="ol">{`The accrued rights of the parties as at termination, or the continuation after termination of any provision expressly stated to survive or implicitly surviving termination shall not be affected or prejudiced.`}</li>
        </ol>
      </li>
      <li parentName="ol">{`Exclusion of Warranties`}
        <ol parentName="li">
          <li parentName="ol">{`Other than as set out in clause 9, the Merchant Offers Platform is provided to the Publisher on an "AS IS" basis and Fidel will not be responsible for any interruptions, delays, failures, or non-availability affecting the Merchant Offers Platform caused by third party services, errors or bugs in third party software, hardware, or the Internet on which Fidel relies to provide the Merchant Offers Platform, or any changes to the Merchant Offers Platform made by or on behalf of the Publisher, and the Publisher acknowledges that Fidel does not control such third party services and that such errors and bugs are inherent in the use of such software, hardware, and the internet.`}</li>
          <li parentName="ol">{`Fidel gives no representations, warranties, conditions or other terms of any kind in respect of the Merchant Offers Platform, whether express or implied, including, but not limited to, warranties of satisfactory quality, merchantability, fitness for a particular purpose, or non-infringement, which are hereby excluded to the full extent permitted by law.`}</li>
          <li parentName="ol">{`The content provided through the Merchant Offers Platform (including the Merchant Content) is provided by Fidel as agent on behalf of the Partners in good faith on an "AS IS" basis, and the Publisher must verify the information before using it.`}</li>
          <li parentName="ol">{`Fidel gives no representations or warranties that the information provided through the Merchant Offers Platform is reliable, accurate, or complete, or that the Publisher’s access to the information will be uninterrupted, timely or secure.`}</li>
          <li parentName="ol">{`Fidel does not accept responsibility for loss suffered as a result of reliance on the accuracy or currency of information provided through the Merchant Offers Platform.`}</li>
          <li parentName="ol">{`Without limiting the effect of clause 11.3, Fidel does not warrant, and nor shall it have any liability in respect of, the following:`}
            <ol parentName="li">
              <li parentName="ol">{`access to the Merchant Content through the Merchant Offers Platform will be error-free or free from interruption; or`}</li>
              <li parentName="ol">{`the Merchant Content is accurate, complete, reliable, secure, useful, fit for purpose, or timely.`}</li>
            </ol>
          </li>
          <li parentName="ol">{`Except as expressly and specifically provided for in these Terms of Service.`}
            <ol parentName="li">
              <li parentName="ol">{`the Publisher assumes sole responsibility for any results obtained from the use of the Merchant Offers Platform and for any conclusions drawn or taken from such use and it relies on the results obtained from such information at its own risk; and`}</li>
              <li parentName="ol">{`all representations, warranties, conditions and all other terms of any kind whatsoever implied by statute or common law are, to the fullest extent permitted by law, excluded from these Terms of Service.`}</li>
            </ol>
          </li>
        </ol>
      </li>
      <li parentName="ol">{`Liability`}
        <ol parentName="li">
          <li parentName="ol">{`Subject to clause 12.2, Fidel will not be liable to the Publisher whether in contract, tort (including negligence or breach of statutory duty), misrepresentation, restitution or otherwise for any loss of income or revenue; loss of business; loss of profits; loss of anticipated savings; loss of data (other than loss of data caused by a breach of these Terms of Service); or any indirect, consequential or special damages, costs or expenses.`}</li>
          <li parentName="ol">{`Nothing in these Terms of Service excludes or limits Fidel’s liability for death or personal injury caused by its negligence or for fraud or fraudulent misrepresentation.`}</li>
          <li parentName="ol">{`Subject to clause 12.2, Fidel’s total liability in contract, tort (including negligence or breach of statutory duty), misrepresentation, restitution or otherwise arising in connection with the performance or contemplated performance of these Terms of Service shall in all circumstances be limited to one hundred pounds (£100).`}</li>
        </ol>
      </li>
      <li parentName="ol">{`Electronic communications`}
        <ol parentName="li">
          <li parentName="ol">{`Applicable laws may require that some of the information or communications Fidel sends to the Publisher should be in writing. When using the Merchant Offers Platform, the Publisher accepts that communication with Fidel will be mainly electronic. Fidel will contact the Publisher by e-mail or provide the Publisher with information by posting notices on the Merchant Offers Platform. For contractual purposes, the Publisher agrees to this electronic means of communication and the Publisher acknowledges that all contracts, notices, information and other communications that Fidel provides to the Publisher electronically comply with any legal requirement that such communications be in writing.`}</li>
        </ol>
      </li>
      <li parentName="ol">{`Notices`}
        <ol parentName="li">
          <li parentName="ol">{`All notices given by the Publisher to Fidel must be given to `}<a parentName="li" {...{
              "href": "mailto:cs@fidelapi.com."
            }}>{`cs@fidelapi.com.`}</a>{` Fidel may give notice to the Publisher at either the e-mail or postal address the Publisher provides to Fidel, or any other way Fidel deems appropriate.`}</li>
          <li parentName="ol">{`Notice will be deemed received and properly served:`}
            <ol parentName="li">
              <li parentName="ol">{`if sent by email, 24 hours after the e-mail is sent; or`}</li>
              <li parentName="ol">{`if sent by post, three days after the date of posting of any letter.`}</li>
            </ol>
          </li>
          <li parentName="ol">{`In proving the service of any notice, it will be sufficient to prove, in the case of a letter, that such letter was properly addressed, stamped and placed in the post and, in the case of an e-mail that such e-mail was sent to the specified e-mail address of the addressee.`}</li>
        </ol>
      </li>
      <li parentName="ol">{`Transfer of rights and obligations`}
        <ol parentName="li">
          <li parentName="ol">{`Neither party may transfer, assign, charge or otherwise deal in these Terms of Service, or any of its rights or obligations arising under these Terms of Service, without the other party’s prior written consent.`}</li>
        </ol>
      </li>
      <li parentName="ol">{`Changes to these Terms`}
        <ol parentName="li">
          <li parentName="ol">{`Fidel may amend these Terms of Service from time to time. Every time the Publisher uses the Merchant Offers Platform, the Publisher acknowledges that it is responsible for checking these Terms of Service to ensure it understands the terms that apply at any time. Any change to these Terms of Service in the future will be posted on this page.`}</li>
        </ol>
      </li>
      <li parentName="ol">{`Events outside a party's control`}
        <ol parentName="li">
          <li parentName="ol">{`No party shall be liable to the other for any delay or non-performance of its obligations under these Terms of Service arising from any cause beyond its control including, without limitation, any of the following: telecommunications failure, internet failure, act of God, governmental act, war, fire, flood, explosion or civil commotion.`}</li>
        </ol>
      </li>
      <li parentName="ol">{`Waiver`}
        <ol parentName="li">
          <li parentName="ol">{`No forbearance or delay by either party in enforcing its rights shall prejudice or restrict the rights of that party, and no waiver of any such rights or of any breach of any contractual terms shall be deemed to be a waiver of any other right or of any later breach.`}</li>
        </ol>
      </li>
      <li parentName="ol">{`Severability`}
        <ol parentName="li">
          <li parentName="ol">{`If any provision of these Terms of Service is judged to be illegal or unenforceable, the continuation in full force and effect of the remainder of the provisions shall not be prejudiced.`}</li>
        </ol>
      </li>
      <li parentName="ol">{`Third party rights`}
        <ol parentName="li">
          <li parentName="ol">{`A person who is not party to these Terms of Service shall not have any rights under or in connection with them under the Contracts (Rights of Third Parties) Act 1999.`}</li>
        </ol>
      </li>
      <li parentName="ol">{`Law and jurisdiction`}
        <ol parentName="li">
          <li parentName="ol">{`These Terms of Service shall be governed by and construed in accordance with English law and each party hereby submits to the exclusive jurisdiction of the English courts.`}</li>
        </ol>
      </li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      